// GENERAL REACT MODULES
import React from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { Container, Row, Col, Button } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AiFillCloseCircle } from "react-icons/ai";
import { DateRangePicker } from 'rsuite';

// PAGE SPECIFIC MODULES
// import AddInsurance from "./AddInsurance/AddInsurance.view.jsx";
// import AddShipments from "./AddShipments/AddShipments.view.jsx";
// import AddDocuments from "./AddDocuments/AddDocuments.view.jsx";
import ShipmentDetails from "./ShipmentDetails/ShipmentDetails.view.jsx";
import "./Shipments.css"
import config_data from "./Shipments.json"

// ALL OTHER MODULES
import TitleBar from "module/components/TitleBar/TitleBar.view.jsx";
import _basePanelView from "components/General/_basePanelView.jsx"
import EditContact from "../Contacts/EditContact/EditContact.view.jsx";
import CotaTotalsBar from "module/components/CotaTotalsBar/CotaTotalsBar.view.jsx";
import Controller from "./Shipments.controller.jsx"
import BookingPanel from "module/pages/Quotes/BookingPanel/BookingPanel.view.jsx"
import SinglePage from "module/pages/Shipments/SinglePage/SinglePage.view.jsx"
import Modal from "./Shipments.modal.jsx";
import { temporalOptions } from "assets/timeFrames.js"
import { attributesOptions } from "./Options.js" 

import ExpandablePartial from "components/Expandable/Expandable.partial.jsx"
import CotaListPanel from "components/CotaListPanel/CotaListPanel.partial.jsx"

export default class Shipments extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller = new Controller({params: {view: this }})
        this.state.refs = {
            _modal_ref: undefined,
            _frame_ref: React.createRef()
        }
        this.state.user_options     = [{ value: "", label: "All" }]
        this.state.customer_options = [{ value: "", label: "All" }]
        this.state.payor_options    = [{ value: "", label: "All" }]
        this.state.status_options   = []
        this.state.radios           = [{ name: 'LTL', value: '1', checked: false },
                                        { name: 'Truckload', value: '2', checked: false }]
        this.state.form             = config_data.form_objects
        this.state.config_data      = config_data
        this.state.panel_name	    = "shipments"
        this.state.channel_name     = "grafana_row"
        this._modal                 = Modal
        this.state.data = {
            loads: [],
            stops: []
        }
        this.state.panel_trees      = [{key: 1,
                                            name: "book_it",
                                            base: 0,
                                            paths: [{
                                                "add_shipment": 0,
                                                "shipment_details": 3,
                                                "single_page": 5, 
                                                "save_action": 0
                                        }]},
                                        {key: 2,
                                            base: 3,
                                            paths: [{
                                                "booking": 0, 
                                                "save_action": 0,
                                                "single_page": 4,
                                                "create_documents": 5}]}]
        this.state.dropdown_options = {
            company_filter: [""],
            time_filter: [""],
            user_filter: [""],
            status_filter: ["Dispatched", "Booked"]
        }
        this.state.channel_name     = ["grafana_row", "grafana_row_click"]
        this.state.panels           = [{key: 0, obj: BookingPanel, params: {panel_params: {is_closeable: true, close_title: "Close", close_func: "resetScreen"}}},
                                        {key: 1, obj: EditContact, params: {panel_params: {is_closeable: true, close_title: "Back", close_func: "backPanel"}}},
                                        {key: 2, obj: EditContact, params: {panel_params: {is_closeable: true, close_title: "Back Home", close_func: "resetScreen"}}},
                                        {key: 3, obj: ShipmentDetails, params: {panel_params: {is_closeable: true, close_title: "Back Home", close_func: "resetScreen"}}},
                                        {key: 4, obj: SinglePage, params: {panel_params: {is_closeable: true, close_title: "Back", close_func: "backPanel"}}},
                                        {key: 5, obj: SinglePage, params: {panel_params: {is_closeable: true, close_title: "Back", close_func: "backPanel"}}}]
    }
    wrapper_mod() {
        if (this.state.context?.authClient?.realmAccess?.roles?.includes('INTERNAL_COTA_USER')) {
            return "flex_wrapper_rows_mod"
        }
        return ""
    }
    handleWindowMouseMove = event => {
        this.state.coords = {
            x: event.clientX,
            y: event.clientY,
        }
    };
    followDidMount() {
        this.mount_listener()
    }
    filter_options() {
        return (
            <>
                <Row>
                    <Col>
                        <Row className="g-0">
                            <Col>
                                Filter by User
                            </Col>
                        </Row>
                        <Row className="form_width g-0">
                            <Col className="dropdown_col">
                                {this.formObject({data: this.state.form.user_filter, options: this.state.user_options, owner: this})}
                            </Col>
                        </Row>
                        <Row className="g-0">
                            <Col>
                                Filter by Payor
                            </Col>
                        </Row>
                        <Row className="form_width g-0">
                            <Col className="dropdown_col">
                                {this.formObject({data: this.state.form.payor_filter, options: this.state.payor_options, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Filter by Status
                            </Col>
                        </Row>
                        <Row className="form_width">
                            <Col >
                                {this.formObject({data: this.state.form.status_filter, options: this.state.status_options, owner: this})}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Filter by Attributes
                            </Col>
                        </Row>
                        <Row className="form_width">
                            <Col>
                                {this.formObject({data: this.state.form.attributes, options: attributesOptions, owner: this})}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Filter by Date
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DateRangePicker format="MM/dd/yyyy" character=" - " onChange={this.controller.change_date_value.bind(this)} />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={3} className="pad_right">
                        <Row>
                            <Col>
                                Carrier
                            </Col>
                        </Row>
                        <Row className="form_width">
                            <Col className='dropdown_col' >
                                {this.formObject({data: this.state.form.customer_filter, options: this.state.customer_options, owner: this})}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Time Frame Quick Select
                            </Col>
                        </Row>
                        <Row className="form_width">
                            <Col className='dropdown_col'>
                                {this.formObject({data: this.state.form.time_frame, options: temporalOptions, owner: this})}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="center_only">
                                <ToggleButtonGroup id="ToggleButtonGroup" name="qoute_group" className="mb-2 ltl_button ltl_button_alignment" variant="outlined" 
                                                    sx={{ p: 1, backgroundColor: 'white' }}>
                                    {this.state.radios.map((radio, index) => (
                                        <ToggleButton className={(radio?.checked ? "untoggled-button-active" : "untoggled-button")+" "+this._theme}
                                                    key={index}
                                                    id={`radio-${index}`}
                                                    value={radio.value}
                                                    onClick={this.controller.toggle_ltl.bind(this, index)}>
                                                        {radio.name}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
    get panel_height() {
        if (this.expandElement?.state?.is_toggled) {
            return 300
        }
        return 150
    }
    get_panel_size() {
        if (this.controller.is_multi_capable()) {
            if (this.state.data.loads.length === 0) {
                return "60% + 160px"
            }
            return "60%"
        }
        return "100%"
    }
    renderMain() {
        window.addEventListener('mousemove', this.handleWindowMouseMove);
        return(
            <Container className="generic_100 g-0" id={this.name} ref={ (divElement) => { this.divElement = divElement } }>
                <Row className="max_height">
                    <Col className={"flex_wrapper_rows "+this.wrapper_mod()}>
                        <Row>
                            <TitleBar params={{parent: this}} />
                        </Row>
                        <ExpandablePartial params={{parent: this, nav_options: {path_num: 0, screen_num: 0, label: "Add Shipments"}, toggable: true }}/>
                        <Row className={"cota_panel_object shipments_panel_one add_shadow "} style={{"height": "calc("+this.get_panel_size()+")"}} ref={ (grafanaElement) => { this.state.refs.grafanaElement = grafanaElement } }>
                            <Col className="generic_100 wrap_cotapanel g-0">
                                <CotaListPanel params={{parent: this, 
                                                        panel_name: this.state.panel_name,
                                                        configs: config_data?.panels,
                                                        endpoint: "loads",
                                                        query: "floads_by_page",
                                                        search_fields: "Cota id, Company, or Carrier",
                                                        is_paginated: true,
                                                        query_params: this.controller.generate_query_params(this.state?.page?.attributes),
                                                        filters: {created_by: this.state?.page?.user_filter, 
                                                                    statuses: this.state?.page?.status_filter,
                                                                    prd: "freightpro",
                                                                    ltl_truckload: this.controller.resolve_ltl_toggle(),
                                                                    nots: ((this.state?.page?.status_filter?.length > 0) ? "" : "status_id"),
                                                                    carrier: this.state?.page?.customer_filter,
                                                                    payor: this.state?.page?.payor_filter,
                                                                    time: this.controller.process_date(this.state?.page?.time_frame)}}}/>
                            </Col>
                        </Row>
                        {this.enable_multi_stop()}
                    </Col>
                    <CotaTotalsBar params={{parent: this, panels: config_data.objects}}/>
                </Row>
            </Container>
        )
    }
    enable_multi_stop() {
        if (this.controller.is_multi_capable()) {
            return (
                <Row className="cota_panel_object shipments_panel_two g-0 add_shadow" onMouseEnter={this.controller.onDragEnd} ref={ (subElement) => { this.subElement = subElement } }>
                    <DragDropContext onDragEnd={this.controller.onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(provided, snapshot) => (
                                <Col {...provided.droppableProps} ref={provided.innerRef} className='drag_window'>
                                    {this.render_stops()}
                                </Col>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Row>
            )
        }
    }
    render_empty_stops() {
        return (
            <Col className="empty_shipments">
                Drag Shipments Here to Create Cota Leads
            </Col>
        )
    }
    render_stops() {
        if (this.state?.data?.stops?.length > 0) {
            return (
                <Row className="ministops_row" id="MultiStopDnD">
                    <Col className="ministop_scroll">
                        <Row style={{height: "100%", minWidth: "100%", width: (this.state?.data?.stops?.length*300)+"px"}}>
                            {this.state?.data?.stops.map((stop, index) => {
                                return (
                                    <Draggable key={"stop_wrapper_"+index} draggableId={"stop_"+index} index={index}>
                                        {(provided, snapshot) => (
                                            <Col className="ministop_wrapper" key={"stop_"+(index+1)} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <Row>
                                                    <Col>
                                                        {index+1}
                                                    </Col>
                                                </Row>
                                                <Row className="ministop">
                                                    <Col>
                                                        <Row>
                                                            <Col className="g-0 align_btn">
                                                                <AiFillCloseCircle className="hide" size={26} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="ministop_title">
                                                                {stop.company}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                {stop.city}, {stop.state}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                {stop.zip}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="center_text">
                                                                {(stop?.type !== undefined) ? stop.type : ""}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                    </Draggable>
                                )
                            })}
                        </Row>
                    </Col>
                    <Col sm={2} className="stops_details">
                        <Row>
                            <Col>
                                <Button className={"primary_button_coloring float_right "+this._theme} onClick={this.controller.save_ml.bind(this)}>
                                    Save
                                </Button>
                            </Col>
                            <Col sm={1} className="g-0 close_col">
                                <AiFillCloseCircle size={26} className={"pointer close_btn"} onClick={this.controller.delete_stop.bind(this, {})}/>
                            </Col>
                        </Row>
                        <Row className="stops_details_row">
                            <Col>
                                Weight
                            </Col>
                            <Col>
                                {this.controller?.multiload?.total?.weight} (lbs)
                            </Col>
                        </Row>
                        <Row className="stops_details_row">
                            <Col>
                                Length
                            </Col>
                            <Col>
                                {this.controller?.multiload?.total?.length} (ft)
                            </Col>
                        </Row>
                        <Row className="stops_details_row">
                            <Col>
                                Distance
                            </Col>
                            <Col>
                                {this.controller?.multiload?.total?.distance} (miles)
                            </Col>
                        </Row>
                        <Row className="stops_details_row">
                            <Col>
                                Complexity
                            </Col>
                            <Col>
                                {this.controller?.multiload?.full_complexity}
                            </Col>
                        </Row>
                        <Row className="stops_details_row">
                            <Col>
                                Trip Duration
                            </Col>
                            <Col>
                                {this.controller?.multiload?.total?.time} (HH:mm)
                            </Col>
                        </Row>
                        <Row className="stops_details_row strong_font">
                            <Col>
                                Rate
                            </Col>
                            <Col>
                                ${this.controller?.multiload?.total?.rate}
                            </Col>
                        </Row>
                        <Row className="stops_details_row">
                            <Col>
                                R/M
                            </Col>
                            <Col>
                                ${this.controller?.multiload?.total?.rm}
                            </Col>
                        </Row>
                        <Row className="stops_details_row green_text">
                            <Col>
                                CO2
                            </Col>
                            <Col>
                                {this.controller?.multiload?.total?.co2}
                            </Col>
                        </Row>
                        <Row className="stops_details_row">
                            <Col>
                                Stops
                            </Col>
                            <Col>
                                {this.controller?.multiload?.stops?.length}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        } else {
            return this.render_empty_stops()
        }
    }
}
