// GENERAL REACT MODULES

// OBJECT SPECIFIC MODULES
import ListLineController from "components/CotaListPanel/ListLineController.jsx"

export default class CotaListPanelController extends ListLineController {
    constructor(props) {
        super(props)
        this.state = {
            fetching: false,
            filters: undefined,
            cache: {
                loaded: 0,
                prev: undefined,
                current: undefined,
                next: undefined
            },
            page_length: 10,
            max_pages: 1
        }
    }
    get configs() {
        return this.view?.props?.params?.configs
    }
    hover_line(hover) {
        this.setState({hover: hover})
    }
    get_hover_class(hover) {
        if (hover) {
            return "hover_line"
        }
        return "unhover_line"
    }
    compareObjs(obj1,obj2){
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }
    get_line_items({endpoint, page, query, args, req_name}) {
        if (!this.state.fetching && this.view._isMounted) {
            if ((this.view.state.called !== this.view.state.page) || (this.view.state.called_length !== this.view.state.page_length) 
                    || !this.compareObjs(this.view?.props?.params.filters, this.state.filters) || !this.compareObjs(this.view.props?.params.query_params, this.state.query_params)) {
                // if (page === undefined) {
                //     if (this.state.cache.loaded < this.view.state.page) {
                //         if (this.state.cache?.next !== undefined) {
                //             return this. ({caller: this, params: {"page": page, "endpoint": endpoint, query: ((query !== undefined) ? query : endpoint), "req_name": req_name }, results: this.state.cache?.next})
                //         }
                //     } else {
                //         if (this.state.cache?.prev !== undefined) {
                //             return this.process_line_items({caller: this, params: {"page": page, "endpoint": endpoint, query: ((query !== undefined) ? query : endpoint), "req_name": req_name }, results: this.state.cache?.prev})
                //         }
                //     }
                // }
                this.get_line_data({endpoint: endpoint, page: page, query: query, args: args, req_name: req_name})
            }
        }
    }
    async get_line_data({endpoint, page, query, args, req_name}) {
        if (page === undefined) {
            this.state.fetching = true
        }
        let fetch_page = (page === undefined) ? this.view.state.page : page
        let page_length = (this.view.state.page_length !== undefined && this.view.state.page_length !== null) ? this.view.state.page_length : 10
        let r = "(l: "+(page_length)+", p: "+(fetch_page-1)
        // drivers page has some challenges with pagination features, so we'll show all drivers on one page and hide pagination nav
        if(endpoint == "drivers"){
            r = "(l: "+(1000)+", p: "+(0)
        }
        // if(this?.view?.state?.filters?.statuses?.[0] !== undefined){
        //     this.view.state.filters.statuses = `${this.view.state.filters.statuses}`
        // }
        let filters = undefined
        this.state.query_params = this.view?.props?.params?.query_params
        if (this.view?.props?.params?.filters !== undefined) {
            filters             = JSON.parse(JSON.stringify(this.view?.props?.params.filters))
            this.state.filters  = filters
            let time            = filters?.time
            r = this.append_filter_object({r: r, filters: filters})
            // SHOULD this be r += this.append_filter_object({r: r, filters: time}) ???
            r = this.append_filter_object({r: r, filters: time})
        }
        r = r+" )"

        let callback = this.process_line_items 
        if (page > 0 || page === undefined) {
            let request = {callback: {f: callback.bind(this), p: {"page": page, "endpoint": endpoint, query: ((query !== undefined) ? query : endpoint), "req_name": req_name }}, endpoint: endpoint, req_name: ((req_name !== undefined) ? req_name : "list"), query: ((query !== undefined) ? query : endpoint), replace: [{o: "()", r: r}, {o: "params", r: this.view?.props?.params.query_params}]}
            this.view.state.loading = true;
            await this.request_data({req: request})
            this.view.state.loading = false;

        }
    }
    append_filter_object({r, filters}) {
        // REVIEW wether or not the Array check is needed or if filters could be an array and handle that case with recursion. 
        if (filters === undefined || filters === null) { return r }
        if (!this.is_obj(filters) || Array.isArray(filters)) { return r }
        Object.keys(filters).map((filter, index) => {
            if (typeof filters[filter] === "string") {
                if (filters[filter] !== "") {
                    r = r+', '+filter+': "'+filters[filter]+'"'
                }
            } else {
                if(Array.isArray(filters[filter])) {
                    if(filters[filter][0] !== undefined) {
                        if (filters[filter].length > 1){
                            r = r+', '+filter+': ['+filters[filter].map((item,index) => {return `"${item}"`})+']'
                        } else {
                            r = r+', '+filter+': ["'+filters[filter]+'"]'
                        }
                        if(filters[filter][0] === undefined){
                            r = r
                        }
                    } else if (filters[filter]?.length > 0) {
                        r = r+", "+filter+": "+'"'+filters[filter]+'"'
                    }
                }
                if (typeof filters[filter] === "boolean" || typeof filters[filter] === "number") {
                    r = r+', '+filter+': '+filters[filter]
                }
            }
        })
        return r
    }
    process_line_items({caller, params, results}) {
        const data = this.view.state?.is_paginated ? results.data?.[params?.query]?.data : results.data?.[params?.query]
        caller.state.fetching       = false
        let req_name = (params?.req_name !== undefined) ? params?.req_name : "list"
        if (results !== undefined) {
            if (params?.page === undefined && (data !== undefined && data !== null)) {
                caller.view.state.called        = caller.view.state.page
                caller.view.state.called_length = (caller.view.state.page_length !== undefined && caller.view.state.page_length !== null) ? caller.view.state.page_length : 10
                caller.view.state.loading       = false
                caller.state.cache.loaded       = caller.view.state.page
                caller.state.cache.current      = data
                caller.setState({key: "line_items", value: data})
                caller.state.cache.prev = undefined
                caller.state.cache.next = undefined
                if(this.view.state?.is_paginated){
                    const paginationData = results.data?.[params?.query]?.pagination_data
                    caller.setState({key: "current_page", value: paginationData.current_page})
                    caller.setState({key: "page_length", value: paginationData.page_size})
                    caller.setState({key: "total_items", value: paginationData.total_items})
                    caller.setState({key: "max_pages", value: paginationData.total_pages})
                }
                // caller.get_line_data({endpoint: params.endpoint, page: caller.view.state.page-1, req_name: req_name})
                // caller.get_line_data({endpoint: params.endpoint, page: caller.view.state.page+1, req_name: req_name})
            } else {
                if (params?.page > caller.view.state.page) {
                    caller.state.cache.next = results
                } else {
                    caller.state.cache.prev = results
                }
            }
        }
    }
    filter_data(data) {
        if (data !== undefined && data !== null) {
            return data.filter(item => !this.view.state?.self_filters?.includes(item?.id))
        }
        return data
    }
    get_max_width() {
        if (this.view.state.max_width !== undefined) {
            return this.view.state.max_width
        }
        return undefined
    }
    get_min_width() {
        if (this.state.max_width !== undefined) {
            return this.state.max_width
        }
        let configs     = this.view?.state?.configs
        let width       = 0
        let widths      = configs?.columns?.widths
        let line_cols   = 0
        if (Array.isArray(widths)) {
            width = widths.reduce((a,v) =>  a = a + v.w , 0 )
        }
        if (Array.isArray(this.view?.state?.line_items)) {
            if (this.view.state.line_items?.length > 0) {
                line_cols   = Object.keys(this.view.state.line_items[0])?.length - ((widths?.length !== undefined) ? widths?.length : 0)
            }
        }
        if (this.view.state.max_width !== undefined) {
            return undefined
        }
        return width + (line_cols * 100)+"px"
    }
    determine_offset() {
        let offset = 0
        if (this.view?.state._header) {
            offset = offset + 48
        }
        if (this.view?.state._nav) {
            offset = offset + 40
        }
        if (this.view?.state._filters) {
            offset = offset + 40
        }
        if (this.view?.state._nav_btm) {
            offset = offset + 40
        }
        return offset
    }
    sort_by({line_items, sort_by}) {
        if (line_items.length > 0) {
            if (typeof line_items?.[0]?.[sort_by] === "string") {
                return line_items.sort((a,b) => this.sort_string({a: a, b: b, dir: this.view?.state?.direction, sort_by: sort_by}))
            }
            if (typeof line_items?.[0]?.[sort_by] === "number") {
                return line_items.sort((a,b) => this.sort_number({a: a, b: b, dir: this.view?.state?.direction, sort_by: sort_by}))
            }
            if (typeof line_items?.[0]?.[sort_by] === "object") {
                return line_items.sort((a,b) => this.sort_string({a: this.unwrap_obj({obj: a, use_root: true}), b: this.unwrap_obj({obj: b, use_root: true}), dir: this.view?.state?.direction, sort_by: sort_by}))
            }
        }
        return line_items
    }
    sort_string({a, b, dir, sort_by}) {
        if (dir) {
            return this.valid(a?.[sort_by]).toString().localeCompare(this.valid(b?.[sort_by]).toString())
        } else {
            return this.valid(b?.[sort_by]).toString().localeCompare(this.valid(a?.[sort_by]).toString())
        }
    }
    sort_number({a, b, dir, sort_by}) {
        if (dir) {
            return (this.valid(a?.[sort_by]) < this.valid(b?.[sort_by]) ? 1 : -1)
        } else {
            return (this.valid(b?.[sort_by]) < this.valid(a?.[sort_by]) ? 1 : -1)
        }
    }
    valid(obj) {
        if (typeof obj === "number") {
            if (obj === null || obj === undefined) {
                return 0
            }
        }
        if (typeof obj === "string") {
            if (obj === null || obj === undefined) {
                return ""
            }
        }
        if (typeof obj === "object") {
            let unwrapped = this.unwrap_obj({obj: obj})
            if (unwrapped === null || unwrapped === undefined) {
                return ""
            } else {
                return unwrapped
            }
        }
        return obj
    }

}
