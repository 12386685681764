// GENERAL REACT MODULES
import _basePartial from "components/General/_basePartial.jsx"
import { Row, Col, Dropdown } from 'react-bootstrap'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

// ALL OTHER MODULES
import "./ListNav.css"
import Controller from "./NewListNav.controller.jsx"
import { RiRefreshFill } from "react-icons/ri";


export default class NewListNav extends _basePartial {
    constructor(props) {
        super(props)
        this.partial_controller = new Controller({params: {view: this}})
        this.state.page_length  = this?.main_context?.self?.settings?.row_length ?? 20
        this.state.page         = 1
        this.state.max_pages    = 1
        this.state.rows         = 1
        this.state.max_rows     = 1
        this.state.search_fields = {query: null}
    }
    get line_height() {
        return 40
    }
    render_refresh() {
        if (!this.state.is_bottom) {
            return (
                <Col sm={1} className="end_buffer font_style" onClick={this.partial_controller?.refresh_results.bind(this)}>
                    <div className="refresh_btn_wrapper">
                        <RiRefreshFill size={20}/>
                    </div>
                </Col>
            )
        } else {
            return (
                <Col sm={1} className="end_buffer"/>
            )
        }
    }

    handleNextPage(displayedItemNumHigh, totalItems){
        // disable if no more pages to show
        if(displayedItemNumHigh > totalItems){
            return
        }
        this.partial_controller.nav.bind(this, true)()
    }

    calculateDisplayedItemNumHigh(displayedItemNumHigh, totalItems){
        if(displayedItemNumHigh > totalItems){
            return totalItems
        }
        return displayedItemNumHigh
    }

    render() {
        const displayedItemNumHigh   =  this.controller.getState({key: "page"})*this.controller.getState({key: "page_length"})
        const displayedItemNumLow    =  (this.controller.getState({key: "page"})-1) * this.controller.getState({key: "page_length"})+1
        const totalItems             =  this.controller.getState({key: "total_items"})
        const currentPage            =  this.controller.getState({key: "page"})
        const maxPages               =  this.controller.getState({key: "max_pages"})

        this.state.page = this.controller?.view?.state?.page
        if(this?.controller?.view?.parent?.state?.reload){
            this.controller.view.state.called = undefined
            this.controller?.view?.forceUpdate()
            this.controller.view.parent.state.reload = false
        }
        if (this.state.search_fields?.query !== null && this.state.search_fields?.query !== undefined) {
            if (this.state.search_fields?.query.length > 1 || this.state.search_fields?.query === "") {
                if (this.state.search_fields?.query !== this.controller.view?.props?.params?.filters?.cota_id) {
                    if (this.controller.view?.props?.params?.filters !== undefined) {
                        this.controller.view.props.params.filters.cota_id = this.state.search_fields?.query
                        this.controller.view.forceUpdate()
                    }
                }
            }
        }
        return (
            <Row id={this.name} style={{"lineHeight": this.line_height+"px", margin:"0.4rem"}} className={((this.state.is_bottom) ? "btm_top" : "") + "d-flex justify-content-between"}>
                <Col className="cnt_search" sm="auto">
                    {this.partial_controller.search_field()}
                </Col>
                <Col>
                    <Row className="d-flex justify-content-end">
                        <Col sm="auto" className="rows_per_page font_style rows_col ">
                                <Row className="g-0 flex-nowrap">
                                    <Col style={{textWrap: "nowrap"}}>
                                        Rows per Page: 
                                    </Col>
                                    <Col>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {this.controller?.view?.state.page_length}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={(e) => { this.partial_controller.selected(5) } }>5</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { this.partial_controller.selected(10) } }>10</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { this.partial_controller.selected(15) } }>15</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { this.partial_controller.selected(20) } }>20</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { this.partial_controller.selected(25) } }>25</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { this.partial_controller.selected(50) } }>50</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                        </Col>
                        {/* <Col sm={1} className="arrow_btn font_style">
                            
                        </Col> */}
                        <Col sm={2} className="rows_per_page font_style" style={{textWrap: "nowrap"}}>
                            Page:  {currentPage} of {maxPages}
                        </Col>
                        <Col sm={2} className="rows_per_page font_style" style={{textWrap: "nowrap"}}>
                            {displayedItemNumLow} - {this.calculateDisplayedItemNumHigh(displayedItemNumHigh, totalItems)} of {totalItems}
                        </Col>
                        <Col sm={1} className="arrow_btn font_style">
                            <div className="arrow_btn_wrapper arrow_btn_wrapper_left" onClick={this.partial_controller.nav.bind(this, false)}>
                                <MdKeyboardArrowLeft size={20}/>
                            </div>
                        </Col>
                        <Col sm={1} className="arrow_btn font_style">
                            <div className="arrow_btn_wrapper arrow_btn_wrapper_right" 
                            onClick={this.handleNextPage.bind(this, displayedItemNumHigh, totalItems)}>
                                <MdKeyboardArrowRight size={20}/>
                            </div>
                        </Col>
                        {this.render_refresh()}
                    </Row>
                </Col>  
            </Row>
        )
    }
}