// GENERAL REACT MODULES
import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { DateRangePicker } from 'rsuite';

// PAGE SPECIFIC MODULES
import TitleBar from "module/components/TitleBar/TitleBar.view.jsx";
import _basePanelView from "components/General/_basePanelView.jsx"
import EditContact from "./EditContact/EditContact.view.jsx";
import ContactDetails from "./ContactDetails/ContactDetails.view.jsx";
// import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx"
import CotaTotalsBar from "module/components/CotaTotalsBar/CotaTotalsBar.view.jsx";
import config_data from "./Contacts.json"
import Controller from "./Contacts.controller.jsx"
import "./Contacts.css"
import { temporalOptions } from "assets/timeFrames.js"
import AlphaBar from "components/AlphaBar/AlphaBar.partial.jsx"
import CotaListPanel from "components/CotaListPanel/CotaListPanel.partial.jsx"

export default class Contacts extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller             = new Controller({params: {view: this}})
        this.state.form             = config_data.form_objects
        this.state.config_data      = config_data
        this.route_keys             = { "/new": 1}
        this.state.screen           = 0
        this.state.panel_name       = "contacts"
        this.state.channel_name     = ["grafana_row"]
        this.state.user_options     = [{ value: "", label: "All" }]
        this.state.panel_trees      = [{key: 0,
                                        name: "carrier_flow",
                                        base: 0,
                                        paths: [{
                                            "edit_contact": 0,
                                            "save_action": 1,
                                            "view_contact": 2,
                                        }]}]
        this.state.panels           = [{key: 0, obj: EditContact, params: {panel_params: {is_closeable: true, close_title: "Back Home", close_func: "resetScreen", controller: this.controller}}},
                                        {key: 1, obj: ContactDetails, params: {panel_params: {is_closeable: true, close_title: "Back Home", close_func: "resetScreen", controller: this.controller}}}]
    }
    followDidMount() {
	    this.mount_listener()
    }
    get panel_height() {
        return 147
    }
    renderMain() {
        return(<>
            <Container className="generic_100 g-0" id={this.name}>
                <Row className="max_height text_color">
                    <Col className="flex_wrapper_rows">
                        <Row>
                            <TitleBar params={{parent: this}} />
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={this.handleScreen.bind(this, {path_num: 0, screen_num: 0})} className={"primary_button_coloring "+this._theme}>
                                    Add Contact
                                </Button>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        Filter by Date
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DateRangePicker format="MM/dd/yyyy" character=" - " onChange={this.controller.change_date_value.bind(this)} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={3} className="pad_right">
                                <Row>
                                    <Col>
                                        Time Frame
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='dropdown_col'>
                                        {this.formObject({data: this.state.form.time_frame, options: temporalOptions, owner: this})}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="filter_row_min_height">
                            <Col sm={3}>
                                <Row><Col>&nbsp;</Col></Row>
                                <Row>
                                    <Col>
                                        {this.formObject({data: this.state.form.user_filter,
                                                            options: this.state.user_options, 
                                                            owner: this,
							    controller: this.controller})}
                                    </Col>
                                </Row>
                                <Row><Col>&nbsp;</Col></Row>
                            </Col>
                            <Col>
                                <Row><Col>&nbsp;</Col></Row>
                                <AlphaBar params={{parent: this.view, controller: this.controller}}/>
                                <Row><Col>&nbsp;</Col></Row>
                            </Col>
                        </Row>
                        <Row className="cota_panel_object generic_100 contacts_panel_one add_shadow">
                            <Col className="generic_100 wrap_cotapanel g-0">
                                <CotaListPanel params={{parent: this, 
                                                        panel_name: this.state.panel_name,
                                                        configs: config_data?.panels,
                                                        req_name: "fchart",
                                                        endpoint: "contacts",
                                                        query: "fcontacts_by_page",
                                                        is_paginated: true,
                                                        filters: {name: this.controller.user_filter, time: this.controller.process_date(this.state?.page?.time_frame)}}}/>
                            </Col>
                        </Row>
                    </Col>
                    <CotaTotalsBar params={{parent: this, panels: config_data.objects}}/>
                </Row>
            </Container>
        </>)
    }
}
