// GENERAL REACT MODULES
import React from "react";
import { Container, Row, Col, ToggleButton, ToggleButtonGroup  } from 'react-bootstrap'
import { DateRangePicker } from 'rsuite';

// PAGE SPECIFIC MODULES
import "./Quotes.css"
import config_data from "./Quotes.json"
import BookingPanel from "./BookingPanel/BookingPanel.view.jsx"
import NewQuotePanel from "./NewQuotePanel/NewQuotePanel.view.jsx";
import EditContact from "../Contacts/EditContact/EditContact.view.jsx";

// ALL OTHER MODULES
import TitleBar from "module/components/TitleBar/TitleBar.view.jsx";
import _basePanelView from "components/General/_basePanelView.jsx"
// import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx"
import CotaTotalsBar from "module/components/CotaTotalsBar/CotaTotalsBar.view.jsx";
import Controller from "./Quotes.controller.jsx"
import { temporalOptions } from "assets/timeFrames.js"

import ExpandablePartial from "components/Expandable/Expandable.partial.jsx"
import CotaListPanel from "components/CotaListPanel/CotaListPanel.partial.jsx"

export default class Quotes extends _basePanelView {
    constructor(props) {
        super(props)
        this.state.form             = config_data.form_objects
        this.state.config_data      = config_data
        this.controller             = new Controller({params: {view: this }})
        this.route_keys             = { "/booking": 2}
        this.state.screen           = 0
        this.state.panelId          = 2
        this.state.panel_name       = "quotes"
        this.state.user_options     = [{ value: "", label: "All" }]
        this.state.customer_options = [{ value: "", label: "All" }]
        this.state.radios           = [{ name: 'LTL', value: '1', checked: false },
                                        { name: 'Truckload', value: '2', checked: false }]
        this.state.channel_name     = ["grafana_button", "grafana_row"]
        this.state.panel_trees      = [{key: 0,
                                            name: "book_it",
                                            base: 0,
                                            paths: [{
                                                "/booking": 0,
                                                "pickup": 1,
                                                "delivery": 1,
                                                "save_action": 0
                                            }]},
                                        {key: 1,
                                            name: "get_new_quote",
                                            base: 2,
                                            paths: [{
                                                "/booking": 0,
                                                "get_quote": 2,
                                                "pickup": 1,
                                                "delivery": 1,
                                                "save_action": 0
                                            }]}]
        this.state.panels           = [{key: 0, obj: BookingPanel, params: {panel_params: {is_closeable: true, close_title: "Back Home", close_func: "handleCloseScreen"}}},
                                        {key: 1, obj: EditContact, params: {panel_params: {is_closeable: true, close_title: "Back", close_func: "backPanel"}}},
                                        {key: 2, obj: NewQuotePanel, params: {panel_params: {is_closeable: true, close_title: "Back Home", close_func: "resetScreen"}}}]
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
    }
    handleCloseScreen() {
        console.log("handleCloseScreen")
        this.resetScreen()
    }
    followDidMount() {
        this.mount_listener()
    }
    render_sidebar() {
        if (this.is_mobile) {
                return (<></>)
        }
        return <CotaTotalsBar params={{parent: this, panels: config_data.objects}}/>
    }
    filter_options() {
        return (
            <>
                <Row>
                    <Col>
                        &nbsp;
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                Filter by Date
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DateRangePicker format="MM/dd/yyyy" character=" - " onChange={this.controller.change_date_value.bind(this)} />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={3} className="pad_right">
                        <Row className={this.controller.display_internal()}>
                            <Col className="form_title_size">
                                Customer
                            </Col>
                        </Row>
                        <Row className={"form_row_width "+this.controller.display_internal()} >
                            <Col className='dropdown_col' >
                                {this.formObject({data: this.state.form.customer_filter, options: this.state.customer_options, owner: this})}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Time Frame
                            </Col>
                        </Row>
                        <Row className="form_row_width">
                            <Col className='dropdown_col'>
                                {this.formObject({data: this.state.form.time_frame, options: temporalOptions, owner: this})}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="filter_row_min_height">
                    <Col>
                        <Row>
                            <Col>
                                Filter by User
                            </Col>
                        </Row>
                        <Row className='pad_left'>
                            <Col className='dropdown_col form_row_width_one'>
                                {this.formObject({data: this.state.form.user_filter, 
                                                    options: this.state?.user_options, 
                                                    owner: this})}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;  
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>
                            <Col className="center_only">
                                <ToggleButtonGroup id="ToggleButtonGroup" type="checkbox" name="qoute_group" className="mb-2 ltl_button ltl_button_alignment" variant="outline-info" 
                                                    sx={{ p: 1, backgroundColor: 'white' }}>
                                    {this.state.radios.map((radio, index) => (
                                        <ToggleButton className={(radio?.checked ? "untoggled-button-active" : "untoggled-button")+" "+this._theme}
                                                    key={index}
                                                    id={`radio-${index}`}
                                                    value={radio.value}
                                                    onClick={this.controller.toggle_ltl.bind(this, index)}>
                                                        {radio.name}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
    get panel_height() {
        if (this.expandElement?.state?.is_toggled) {
            return 260
        }
        return 80
    }
    renderMain() {
        return(
            <Container className="generic_100 g-0" id={this.name}>
                <Row className="max_height">
                    <Col className="flex_wrapper_rows">
                        <Row>
                            <TitleBar params={{parent: this}} />
                        </Row>
                        <ExpandablePartial params={{parent: this, nav_options: {path_num: 1, screen_num: 2, label: "Get Quote"}, toggable: true }}/>
                        <Row className="cota_panel_object quotes_panel_one add_shadow" style={{"height": "calc(100% - "+this.panel_height+"px)", "width": "100%"}}>
                            <Col className="generic_100 wrap_cotapanel g-0">
                                <CotaListPanel params={{parent: this, 
                                                        configs: config_data?.panels,
                                                        endpoint: "quotes",
                                                        query: "quotes_by_page",
                                                        is_paginated: true,
                                                        filters: {creator: this.state?.page?.user_filter,
                                                                    customer: this.state?.page?.customer_filter,
                                                                    ltl_truckload: this.controller.resolve_ltl_toggle(),
                                                                    time: this.controller.process_date(this.state?.page?.time_frame)}}}/>
                            </Col>
                        </Row>
                    </Col>
                    <CotaTotalsBar params={{parent: this, panels: config_data.objects}}/>
                </Row>
            </Container>
        )
    }
}
